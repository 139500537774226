<template>
	<div>
		<img @click="goBack" src="../../assets/image/luckDraw/blackBack.png" class="back">
		<div class="top">
			<div class="luckDraw">
				<!-- <img :src="!check?lp1:lp2" class="lpStyle"> -->
				<div ref="in" :class="['lpBox',!check?'lpBg1':'lpBg2']">
					<div class="lpChild" v-for="(item,index) in list" :key='index'
						:style="{transform: `rotate(${item.angleNum}deg)`}">
						<img :src="host+item.thumb" class="imageStyle">
						<div class="lpChildTitle">{{item.name}}</div>
					</div>
				</div>
				<!-- <img @click="$throttle(cj)" src="../../assets/image/luckDraw/start.png" class="drawStart"> -->
				<img @click="cj()" src="../../assets/image/luckDraw/start.png" class="drawStart">
			</div>
			<div class="luckNumber">剩余抽奖次数：{{number}}</div>
			<div class="dtBtn" @click="show = true">答题获得抽奖机会</div>
			<!-- <div class="peopleNum">已有<span style="color: #FDF6CF;">813900</span>人参与</div> -->
			<div class="winWrap">
				<div id="win">
					<div style="display: flex;justify-content: center;" v-for="(item,index) in giftPeople" :key='index'>
						<div class="winChild">{{item}}</div>
						<div></div>
					</div>
				</div>
			</div>
			<div class="btm"></div>
		</div>
		<div style="background: #DD1C25;padding-bottom: 10px;box-sizing: border-box;">
			<div class="myGift">
				<img src="../../assets/image/luckDraw/wdjp.png" class="myGiftImage">
				<img src="../../assets/image/luckDraw/left.png" class="myGiftLeft">
				<img src="../../assets/image/luckDraw/right.png" class="myGiftRight">
				<div style="margin: 13px;z-index: 3;position: relative;">
					<div class="myGiftBox" v-if="giftList.length==0">
						<div class="noGift">暂无中奖记录</div>
					</div>
					<div v-else class="myGiftBox" style="display: flex;align-items: center;"
						v-for="(item,index) in giftList" :key='index'>
						<img :src="host+item.thumb" style="width: 120px;height: 120px;">
						<div style="margin-left: 32px;display: flex;flex-flow: column;align-items: center;">
							<div style="color: #EF6937;font-size: 14px;font-weight: bold;">{{item.prize_name}}</div>
							<div class="btn" @click="receiveGift(item,index)">领取</div>
						</div>
					</div>
				</div>
			</div>
			<div class="myGift" style="margin-top: 14px;">
				<img src="../../assets/image/luckDraw/hdsm.png" class="myGiftImage">
				<img src="../../assets/image/luckDraw/left.png" class="myGiftLeft">
				<img src="../../assets/image/luckDraw/right.png" class="myGiftRight">
				<div class="hdBox">
					<div v-html="content.content"></div>
				</div>
			</div>
		</div>
		<div class="mask" v-if="maskShow">
			<img v-if="giftResult.id==7 || !giftResult.id || number==0" src="../../assets/image/luckDraw/cy.png"
				class="maskChildTop">
			<img v-else src="../../assets/image/luckDraw/zj.png" class="maskChildTop">
			<img src="../../assets/image/luckDraw/close.png" class="close" @click="tryAgain">
			<div class="maskChild">
				<div class="maskChildWz">
					抽奖次数剩余次数：{{number}}
				</div>
				<div>
					<div style="text-align: center;" v-if="giftResult.id!=7 && number!=0 && number>0">
						<img :src="host+giftResult.thumb" style="width: 100px;height: 82px;">
						<div style="color: #EF6937;font-size: 20px;font-weight: bold;">{{giftResult.name}}</div>
					</div>
					<div v-if="number==0 || number<0" style="text-align: center;">
						<div style="margin-top: 32px;color: #676767;font-size: 20px;font-weight: bold;">
							您的抽奖次数已用完~
						</div>
						<div style="color: #676767;font-size: 14px;margin: 7px 0 25px 0;">谢谢您的参与</div>
					</div>
					<div style="text-align: center;" v-if="giftResult.id==7 && number!=0">
						<img :src="host+giftResult.thumb" style="width: 65px;height: 65px;margin-top: 12px;">
						<div style="margin-top: 8px;color: #676767;font-size: 20px;font-weight: bold;">
							再接再厉
						</div>
					</div>
				</div>
				<div @click="tryAgain" class="CjBtn">再来一次</div>
			</div>
		</div>
		
		<!-- 禁止抽奖 -->
		<div class="mask" v-if="chouVisble">
			<!-- <img v-if="giftResult.id==7 || !giftResult.id || number==0" src="../../assets/image/luckDraw/cy.png"
				class="maskChildTop"> -->
			<!-- <img v-else src="../../assets/image/luckDraw/zj.png" class="maskChildTop"> -->
			<img src="../../assets/image/guanbi.png" class="close" @click="chouClick">
			<div class="maskChild" style="border-radius: 24px !important;overflow: hidden;height: 340px;">
				<img src="../../assets/image/backImg.png" style="width: 100%;height: 100%;" />
				<!-- <div class="maskChildWz">
					抽奖次数剩余次数：{{number}}
				</div> -->
				<!-- <div>
					<div style="text-align: center;" v-if="true">
						<img :src="host+giftResult.thumb" style="width: 100px;height: 82px;">
						<div style="color: #EF6937;font-size: 20px;font-weight: bold;">{{giftResult.name}}</div>
					</div>
					<div v-if="number==0 || number<0" style="text-align: center;margin-top: 1;">
						<div style="margin-top: 82px;color: #676767;font-size: 20px;font-weight: bold;">
							抽奖时间已结束~
						</div>
						<div style="color: #676767;font-size: 14px;margin: 7px 0 25px 0;">谢谢您的参与</div>
					</div>
					<div style="text-align: center;" v-if="giftResult.id==7 && number!=0">
						<img :src="host+giftResult.thumb" style="width: 65px;height: 65px;margin-top: 12px;">
						<div style="margin-top: 8px;color: #676767;font-size: 20px;font-weight: bold;">
							再接再厉
						</div>
					</div>
				</div> -->
				<!-- <div @click="tryAgain" class="CjBtn">再来一次</div> -->
			</div>
		</div>

		<div class="mask" v-if="loginShow">
			<img src="../../assets/image/luckDraw/close.png" class="close" @click="loginShow = false">
			<div class="maskChild">
				<div class="maskTop">
					欢迎参加2022瑞士冰雪节
				</div>
				<div class="maskPhone">
					<input @input="checkInput" v-model="phone" type="number" placeholder="请输入账号" class="phones" />
				</div>
				<div class="maskPhone">
					<input @input="checkInput" v-model="code" type="password" placeholder="请输入密码" class="phones" />
				</div>
				<!-- <div class="maskCodeWrap">
					<div class="maskCodeLeft">
						<input @input="checkInput" v-model="code" type="text" placeholder="请输入验证码" class="phones" />
					</div>
					<div class="maskCodeRight" :style="{background:yzm?'#DD1C25':'#86838C'}" @click="getCode">
						{{btnText}}
					</div>
				</div> -->
				<div class="xy" style="height: 20px;">
					<!-- <div style="font-size: 12px;color: #86838C;">
						<input type="checkbox" class="checkBoxs" />
						新用户登录即注册，代表我已阅读并同意
						<span style="color: #348AFE;">用户协议</span>
						和
						<span style="color: #348AFE;">隐私协议</span>
					</div> -->
				</div>
				<div style="position: relative;">
					<div @click="login" class="loginBtn" :style="{background:inputStatus?'#DD1C25':'#86838C'}">
						{{types?'注册':'登录'}}
					</div>
					<div @click="Setypes"
						style="position: absolute;top: 28%;right: 7%;color: #86838C;font-size: 12px;letter-spacing: 1px;">
						{{!types?'注册':'登录'}}
					</div>
				</div>
				<div style="color: #434444;font-size: 12px;text-align: center;">登录即可获得一次抽奖机会哦～</div>
			</div>
		</div>

		<van-popup v-model="show" closeable round position="bottom" style="height: 312px;"
			:close-on-click-overlay='false'>
			<div style="padding: 0 19px;box-sizing: border-box;">
				<div style="text-align: center;padding-top: 23px;box-sizing: border-box;">
					<img :src="require('../../assets/image/luckDraw/topWz.png')" style="width: 234px;height: 20px;">
				</div>
				<div style="margin-top: 12px;">
					<div style="color: #434444;font-size: 12px;">已答对<span class="numbers">{{correctNum}}</span>题</div>
				</div>
				<div v-if="!answerOk">
					<div style="margin-top: 3px;position: relative;">
						<div style="color: #434444;font-size: 16px;">{{subject.name}}</div>
						<img v-if="subject.status=='2'" :src="require('../../assets/image/luckDraw/cuowu.png')"
							class="answerIcon">
						<img v-else-if="subject.status=='1'" :src="require('../../assets/image/luckDraw/zhengque.png')"
							class="answerIcon">
						<div style="height: 20px;margin-top: 14px;" v-if="subject.status=='2'">
							<div class="answers">答案：{{subject.answer}}</div>
						</div>
					</div>
					<div v-if="subject.status=='' || subject.status=='1'" style="margin-top: 28px;">
						<div @click="answerClick(item,index)" :class="['question',item.status=='1'
							?'colorOne':item.status=='2'?'colorTwo':'noColor']" v-for="(item,index) in subject.options" :key='index'>
							{{item.name}}
						</div>
					</div>
					<div class="jx" v-if="subject.status=='2'">
						<div class="jxAbsoules">解析</div>
						<div class="jxChild">{{subject.analysis}}</div>
					</div>
					<!-- 占位盒子 -->
					<div style="height: 40px;">
					</div>
					<div class="answerBtm align-center just-center">
						<div style="margin-right: 23px;color: #86838C;font-size: 12px;" @click="up">上一题</div>
						<div style="color: #434444;font-size: 12px;">第{{current}}/{{subjectList.length}}题</div>
						<div style="margin-left: 23px;color: #348AFE;font-size: 12px;" @click="down">下一题</div>
					</div>
				</div>

				<div v-else>
					<div class="answerOKs">
						恭喜您获得一次抽奖机会～
					</div>
					<div class="okBtn" @click="show = false">
						去抽奖
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import {
		giftIndex,
		lotterys,
		questionIndex,
		subQuestion,
		myPrize,
		prizeNum,
		active
	} from '../../api/home.js'
	import {
		userAddress
	} from '../../api/mine.js'
	import {
		host
	} from '../../utils/host.js'
	import {
		login,
		register
	} from '../../api/login.js'
	export default {
		name: 'luckDraw',
		data() {
			return {
				content: {},
				types: false, //false是登录  true是注册
				host: host,
				answerOk: false,
				current: 1,
				show: false, //打开答题
				btnText: '获取验证码',
				phone: '', //手机号
				code: '', //验证码
				checked: false, //是否勾选协议
				maskShow: false, //打开中奖或者未中奖弹窗
				chouVisble: false, // 抽奖结束弹框
				loginShow: false, //打开登录弹窗
				inputStatus: false, //是否填完input
				lp1: require('../../assets/image/luckDraw/lp1.png'), //轮盘背景
				check: false, //控制轮盘图片变换
				lp2: require('../../assets/image/luckDraw/lp2.png'), //轮盘背景
				list: [], //奖品列表
				giftPeople: [], //获奖人列表
				startRotatingDegree: 0, //当前所在的度数
				selectIndex: 0, //奖品下标
				rdm: 1800, //旋转的度数
				giftList: [], //本人获得的奖品
				is_none: null,
				yzm: true,
				subject: {}, //当前展示的题目
				subjectList: [],
				correctNum: 0,
				giftResult: {}, //抽奖的结果
				token: window.localStorage.getItem('token'),
				number: 0,
			}
		},
		created() {
			this.getGiftList()
			this.checkStatus()
			this.getSubject()
			this.getActivity()
			setTimeout(() => {
				this.getWinPeople()
			}, 500)
		},
		mounted() {
			if (this.token) {
				this.getMyGift()
				this.getGiftNum()
			}
		},
		methods: {
			getActivity() {
				active().then(res => {
					console.log('活动说明', res)
					this.content = res.data
				})
			},
			// 获取抽奖次数
			getGiftNum() {
				let data = new FormData()
				data.append('token', window.localStorage.getItem('token'))
				prizeNum(data).then(res => {
					console.log('抽奖次数', res)
					this.number = res.data.prize_num
				})
			},
			// 获取抽到的奖品
			getMyGift() {
				let data = new FormData()
				data.append('token', window.localStorage.getItem('token'))
				myPrize(data).then(res => {
					console.log('我获得的奖品', res)
					this.giftList = res.data.data
				})
			},
			login() {
				let that = this
				if (!this.inputStatus) {
					return
				}
				let data = new FormData()
				data.append('mobile', this.phone)
				data.append('password', this.code)
				if (this.types) {
					register(data).then(res => {
						console.log('注册成功', res)
						this.loginShow = false
						window.localStorage.setItem('token', res.data.userinfo.token)
						setTimeout(()=>{
							this.getMyGift()
							this.getGiftNum()
						},500)
						this.$toast('注册成功');
					})
				} else {
					login(data).then(res => {
						console.log('登录成功', res)
						this.loginShow = false
						window.localStorage.setItem('token', res.data.userinfo.token)
						setTimeout(()=>{
							this.getMyGift()
							this.getGiftNum()
						},500)
						this.$toast('登录成功');
					})
				}
			},
			chouClick() {
				this.chouVisble = false;
			},
			Setypes() {
				this.types = !this.types
			},
			tryAgain() {
				this.getGiftNum()
				this.getMyGift()
				this.maskShow = false
			},
			// 点击答题
			answerClick(item, index) {
				let list = this.subjectList[this.current - 1].options
				let clickStatus = true
				list.forEach(i => {
					if (i.status != '') {
						clickStatus = false
					}
				})
				if (!clickStatus) {
					return
				}
				if (item.name == this.subjectList[this.current - 1].answer) {
					this.subjectList[this.current - 1].status = '1'
					this.subjectList[this.current - 1].options[index].status = '1'
					item.status = '1'
					this.subject.status = '1'
					this.correctNum++
				} else {
					this.subjectList[this.current - 1].status = '2'
					this.subjectList[this.current - 1].options[index].status = '2'
					this.subject.status = '2'
					item.status = '2'
				}

				if (this.subject.id == this.subjectList[4].id) {
					this.answerOk = true
					this.addNum()
					setTimeout(() => {
						this.getGiftNum()
					}, 500)
				}
			},
			// 答完五道题增加抽奖次数
			addNum() {
				let data = new FormData()
				data.append('token', this.token)
				subQuestion(data).then(res => {
					console.log('增加抽奖次数', res)
				})
			},
			// 获取题目
			getSubject() {
				questionIndex().then(res => {
					console.log('获取随机五道题', res)
					let list = res.data
					list.forEach(item => {
						item.status = ''
						item.options.forEach(i => {
							i.status = ''
						})
					})
					this.subjectList = list
					this.subject = this.subjectList[0]
				})
				// let list = [{
				// 	id: 1,
				// 	title: '1. 植物也怕“痒”，下面哪种植物被称为痒痒树',
				// 	answer: '紫薇',
				// 	answerList: [{
				// 			name: '玉兰',
				// 			status: ''
				// 		},
				// 		{
				// 			name: '紫薇',
				// 			status: ''
				// 		}
				// 	],
				// 	status: '', //true答题正确  false是答题错误
				// 	analysis: `痒痒树学名紫薇，也称无皮树。
				// 		紫薇树皮光滑，一挠枝干整株都会摆动，看起来很怕“痒”。,
				// 		因为挠枝干时，摩擦引起的震动很容易通过
				// 		坚硬的枝干传导到顶端的枝叶和花朵，
				// 		引起晃动，晃动逐渐积累，幅度就会越来越大。`
				// }]
			},
			// 获取验证码
			getCode() {
				let phone = this.phone
				let is_none = this.is_none
				let regPhone = /^1(3|4|5|6|7|8|9)\d{9}$/;
				let that = this
				if (is_none != 'none') {
					if (!regPhone.test(phone)) {
						this.$toast('请输入正确的手机号');
					} else {
						that.yzm = false
						let coden = 60 // 定义60秒的倒计时
						let codeV = setInterval(function() {
							that.btnText = (--coden) + 's';
							that.is_none = 'none';
							if (coden == 0) { // 清除setInterval倒计时，这里可以做很多操作，按钮变回原样等
								that.btnText = '获取验证码';
								that.is_none = '';
								clearInterval(codeV);
								that.yzm = true
							}
						}, 1000) //  1000是1秒
						this.$toast('发送成功,请查看手机');
						// 验证码接口
					}
				}

			},
			// 领取礼物
			receiveGift(item, index) {
				let data = new FormData()
				data.append('token', this.token)
				userAddress(data).then(res => {
					console.log('是否有地址', res)
					if (res.data == null) {
						this.$router.push({
							path: '/fillInAddress',
							query: {
								id: item.id
							}
						})
					} else {
						this.$router.push({
							path: '/giftReceive',
							query: {
								id: item.id
							}
						})
					}
				})
			},
			// 获取中奖人员名单
			getWinPeople() {
				console.log(this.giftPeople)
				this.$nextTick(() => {
					let len = this.giftPeople.length;
					let ti = len * 2;
					let n = len * 110;
					n = 100
					var dom = document.getElementById('win');
					if (dom) {
						var sty = document.styleSheets[0];
						sty.deleteRule(1);
						sty.insertRule(
							"@keyframes run{0%{ transform: translateY(0%);} 100%{transform: translateY(-" + n +
							"%);}}", 1); //写入样式
						dom.style.animation = 'run ' + ti + 's linear infinite';
					}
				})
			},
			// 获取礼品列表
			getGiftList() {
				giftIndex().then(res => {
					console.log('奖品', res)
					this.giftPeople = res.data.win
					res.data.prize.forEach((item, index) => {
						item.angleNum = Math.round(360 / res.data.prize.length) * index
					})
					this.list = res.data.prize
					console.log(this.list)
				})
			},
			// 抽奖
			// async ci()
			cj() {
				this.chouVisble = true;
				return
				// let token = window.localStorage.getItem('token')
				// if (!token) {
				// 	this.loginShow = true
				// 	return
				// }
				// if (this.number == 0 || this.number < 0) {
				// 	this.maskShow = true
				// 	return
				// }
				// let inBox = this.$refs['in']
				// let _this = this
				// let a = await _this.cjjg()
				// // console.log(a, 'id')
				// // console.log(360 - _this.list[a].angleNum, '所在的度数')
				// _this.rdm = _this.rdm + (360 - _this.list[a].angleNum) - _this.startRotatingDegree
				// // console.log('应该旋转的度数', _this.rdm)
				// inBox.style.transition = "all 5s ease 0s";
				// inBox.style.transform = `rotate(${_this.rdm}deg)`
				// setTimeout(() => {
				// 	this.realse()
				// }, 6000)
			},
			// 模拟后台抽到奖品
			cjjg() {
				return new Promise((resolve, rej) => {
					let data = new FormData()
					data.append('token', window.localStorage.getItem('token'))
					lotterys(data).then(res => {
						console.log('抽奖结果', res)
						this.giftResult = res.data
						let id = res.data.id
						console.log(id, 'id')
						this.list.forEach((item, index) => {
							if (item.id == id) {
								console.log(index, '下标')
								this.selectIndex = index
								resolve(index)
							}
						})
					})
				})
			},
			// 重置转盘
			realse() {
				this.maskShow = true
				this.startRotatingDegree = this.rdm - 1800
				let inBox = this.$refs['in']
				inBox.style.transition = "all 0s ease 0s"
				inBox.style.transform = `rotate(${this.startRotatingDegree}deg)`
			},
			// 切换转盘图片
			checkStatus() {
				setInterval(() => {
					this.check = !this.check
				}, 500)
			},
			// 返回上一级
			goBack() {
				this.$router.go(-1)
			},
			// 检查是否填写完所有input
			checkInput() {
				if (this.phone && this.code) {
					this.inputStatus = true
				} else {
					this.inputStatus = false
				}
			},
			// 上一题
			up() {
				if (this.current == 1) {
					return
				}
				this.current--
				this.subject = this.subjectList[this.current - 1]
			},
			// 下一题
			down() {
				if (this.subject.status == '') {
					this.$toast('请先答完这道题');
					return
				}
				if (this.current < 5) {
					this.current++
				}
				this.subject = this.subjectList[this.current - 1]
			},
		}
	}
</script>

<style scoped>
	.colorOne {
		background: #16E98B;
		color: #fff;
	}

	.colorTwo {
		background: #FE6868;
		color: #fff;
	}

	.noColor {
		background: #F5F6F8;
	}

	.top {
		background-image: url(../../assets/image/luckDraw/cjBg.png);
		width: 100%;
		height: 20.6rem;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		position: relative;
	}

	.luckDraw {
		position: relative;
		top: 20%;
		width: 100%;
	}

	.lpStyle {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.lpBox {
		width: 9.5rem;
		height: 9.5rem;
		left: 3%;
		position: relative;
		border-radius: 50%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.lpBg1 {
		background-image: url(../../assets/image/luckDraw/lp1.png);
	}

	.lpBg2 {
		background-image: url(../../assets/image/luckDraw/lp2.png);
	}

	.imageStyle {
		width: 60px;
		height: 60px;
	}

	.lpChild {
		width: 1.56rem;
		height: 4.6rem;
		position: absolute;
		text-align: center;
		top: 2%;
		left: 42%;
		transform-origin: bottom center;
		padding-top: 0.8rem;
		box-sizing: border-box;
	}

	.lpChildTitle {
		color: #E16D20;
		font-size: 14px;
		font-weight: bold;
	}

	.drawStart {
		width: 88px;
		height: 120px;
		position: absolute;
		top: 31%;
		left: 40%;
	}

	.luckNumber {
		margin-top: 47%;
		text-align: center;
		color: #fff;
		font-size: 20px;
	}

	.peopleNum {
		text-align: center;
		margin-top: 7px;
		color: #FFEA00;
		font-size: 16px;
		letter-spacing: 1px;
	}

	.winWrap {
		width: 100%;
		height: 120px;
		/* margin-top: 46px; */
		margin-top: 20px;
		overflow: hidden;
	}

	#win {}

	.winChild {
		height: 24px;
		background: linear-gradient(180deg, #FFEB9D 0%, rgba(255, 234, 155, 0.23) 100%);
		padding: 0 15px;
		box-sizing: border-box;
		text-align: center;
		line-height: 24px;
		margin-top: 7px;
		color: #E35320;
		font-size: 14px;
		font-weight: bold;
		border-radius: 13px;
	}

	.btm {
		width: 100%;
		height: 40px;
		background: #DD1C25;
		position: absolute;
		bottom: -35px;
	}

	.myGift {
		width: 92%;
		margin: 0 auto;
		/* margin-top: 30px; */
		margin-top: 10px;
		background: #FDF6CF;
		border-radius: 13px;
		position: relative;
		padding: 12px;
		box-sizing: border-box;
	}

	.myGiftImage {
		width: 126px;
		height: 33px;
		position: absolute;
		top: 12px;
		left: 12px;
	}

	.myGiftLeft {
		width: 228px;
		height: 110px;
		position: absolute;
		left: 12px;
		bottom: 12px;
	}

	.myGiftRight {
		width: 148px;
		height: 158px;
		position: absolute;
		right: 12px;
		top: 12px;
	}

	.myGiftBox {
		height: 125px;
		margin-top: 22px;
	}

	.myGiftBox:nth-child(1) {
		margin-top: 36px;
	}

	.btn {
		width: 70px;
		height: 24px;
		background: #DD1C25;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 19px;
		text-align: center;
		line-height: 24px;
		margin-top: 18px;
		color: #fff;
		letter-spacing: 1px;
	}

	.noGift {
		color: #E35320;
		font-size: 18px;
		font-weight: bold;
		line-height: 125px;
		text-align: center;
	}

	.hdBox {
		margin-top: 64px;
		padding: 12px;
		box-sizing: border-box;
		color: #E35320;
		font-size: 14px;
	}

	.maskChild {
		width: 280px;
		height: 255px;
		background: #FFFFFF;
		border-radius: 12px;
		position: fixed;
		top: 35%;
		left: 15%;
	}

	.maskChildTop {
		width: 222px;
		height: 53px;
		position: fixed;
		top: 32.5%;
		left: 22%;
		z-index: 2;
	}

	.maskChildWz {
		font-size: 14px;
		color: #EF6937;
		margin-top: 46px;
		text-align: center;
	}

	.CjBtn {
		width: 168px;
		height: 38px;
		background: #DD1C25;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 19px;
		line-height: 38px;
		text-align: center;
		font-size: 16px;
		letter-spacing: 2px;
		margin: 0 auto;
		margin-top: 12px;
		color: #fff;
	}

	.close {
		width: 30px;
		height: 30px;
		position: fixed;
		top: 30%;
		right: 11%;
	}

	.back {
		width: 10px;
		height: 17px;
		position: fixed;
		top: 6%;
		z-index: 2;
		left: 5%;
	}

	.dtBtn {
		width: 150px;
		height: 24px;
		background: #FDF6CF;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 19px;
		text-align: center;
		line-height: 24px;
		color: #DD1C25;
		letter-spacing: 1px;
		font-size: 14px;
		margin: 0 auto;
		margin-top: 10px;
	}

	.maskTop {
		color: #434444;
		font-size: 20px;
		font-weight: bold;
		text-align: center;
		padding-top: 18px;
		box-sizing: border-box;
	}

	.maskPhone {
		height: 32px;
		border-radius: 21px;
		border: 1px solid #979797;
		margin: 0 auto;
		margin: 14px 17px 0 17px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.phones {
		text-align: center;
		width: 90%;
		font-size: 14px;
	}

	input::-webkit-input-placeholder {
		color: #AEADB1;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #AEADB1;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #AEADB1;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #AEADB1;
	}

	.maskCodeWrap {
		margin: 12px 17px 0 17px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.maskCodeLeft {
		width: 161px;
		height: 32px;
		text-align: center;
		border-radius: 20px;
		border: 1px solid #979797;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.maskCodeRight {
		width: 75px;
		height: 32px;
		border-radius: 20px;
		text-align: center;
		line-height: 32px;
		font-size: 12px;
		color: #fff;
	}

	.xy {
		margin: 8px 17px 0 17px;
	}

	.checkBoxs {
		width: 16px;
		height: 16px;
		border-radius: 4px;
		border: 1px solid #979797;
	}

	.loginBtn {
		width: 168px;
		height: 38px;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 19px;
		margin: 0 auto;
		color: #fff;
		text-align: center;
		line-height: 38px;
		margin-top: 9px;
	}

	.numbers {
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 4px;
		background: #348AFE;
		text-align: center;
		line-height: 18px;
		color: #fff;
		margin: 0 4px;
	}

	.answerIcon {
		width: 60px;
		height: 60px;
		position: absolute;
		top: -30%;
		right: 5%;
	}

	.answers {
		color: #434444;
		font-size: 14px;
		border-bottom: 1px solid #F5F6F8;
		padding-bottom: 19px;
		box-sizing: border-box;
	}

	.jx {
		position: relative;
		margin-top: 29px;
	}

	.jxAbsoules {
		position: absolute;
		left: 0;
		width: 36px;
		height: 17px;
		background: #348AFE;
		border-radius: 4px;
		line-height: 17px;
		text-align: center;
		color: #fff;
	}

	.jxChild {
		text-indent: 4em;
		color: #434444;
		line-height: 20px;
		letter-spacing: 1px;
		font-size: 14px;
	}

	.answerBtm {
		/* width: 100%; */
		width: 90%;
		height: 40px;
		position: fixed;
		bottom: 0;
		display: flex;
		background: #fff;
	}

	.question {
		width: 280px;
		height: 40px;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 20px;
		text-align: center;
		line-height: 40px;
		margin: 0 auto;
		margin-top: 24px;
	}

	.answerOKs {
		margin-top: 52px;
		color: #434444;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}

	.okBtn {
		width: 280px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background: #DD1C25;
		box-shadow: 0px 2px 14px 0px rgba(182, 181, 181, 0.5);
		border-radius: 20px;
		color: #fff;
		letter-spacing: 2px;
		margin: 0 auto;
		margin-top: 57px;
		font-size: 16px;
	}
</style>
